/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import Page from "../layouts/page"

import { Formik, Form, Field } from 'formik'
import { useState } from "react"

const styles = css`
h2 {
  margin-bottom: 0;
}

p.first {
  margin-top: 8px;
  margin-bottom: 0px;
}

ul {
  margin-top: 8px;
}

.contact-split {
  display: flex;

  .contact-split-item {
    width: 50%;
    padding: 4px;
  }
}

@media (max-width: 800px) {
  .contact-split {
    flex-flow: column;

    .contact-split-item {
      width: auto;
    }
  }
}
`

const ContactUsPage = () => {

  const [formSent, setFormSent] = useState(false)

  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <h1>Contact Us</h1>
        <p>
          We're happy to answer any questions you may have. Get in touch with us and we'll be back to you as soon as possible.<br/>
          We are also available for contact through the live chat in the bottom right of the page.
        </p>

        <div className="contact-split">

          <div className="contact-split-item">

            <h2>Sales</h2>
            <p className="first">Interested in a service we offer or have any questions?</p>
            <ul>
              <li>Email: <a href="mailto:sales@infernocomms.com">sales@infernocomms.com</a></li>
              <li>Phone: <a href="tel:+443337999999">+44 (0) 3337 999 999</a></li>
            </ul>

            <h2>Support</h2>
            <p className="first">Need help with a service from us?</p>
            <ul>
              <li>Email: <a href="mailto:support@infernocomms.com">support@infernocomms.com</a></li>
              <li>Phone: <a href="tel:+443337999999">+44 (0) 3337 999 999</a></li>
            </ul>

            <h2>Networks</h2>
            <p className="first">Peering requests and abuse reports</p>
            <ul>
              <li>NOC: <a href="mailto:noc@infernocomms.com">noc@infernocomms.com</a></li>
              <li>Abuse: <a href="mailto:abuse@infernocomms.com">abuse@infernocomms.com</a></li>
              <li>Phone: <a href="tel:+443337999999">+44 (0) 3337 999 999</a></li>
            </ul>

          </div>

          <div className="contact-split-item">

            <Formik
              initialValues={{ name: '', email: '', purpose: 'Sales', message: '' }}
              validate={values => {
                const errors = {}
                if(!values.name) {
                  errors.name = 'Name is required'
                }
                if(!values.email) {
                  errors.email = 'Email address is required'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'A valid email address is required'
                }
                if(!values.purpose) {
                  errors.purpose = 'A purpose is required'
                }
                if(values.message === '') {
                  errors.message = 'A message is required'
                } else {
                  const letters = values.message.length
                  if(letters < 10) {
                    errors.message = 'Your message should be ' + (10 - letters) + ' characters longer'
                  }
                }

                return errors
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                fetch('/api/contact', {
                  method: 'PUT',
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                })
                  .then((resp) => {
                    setSubmitting(false)
                    if(resp.status !== 200) {
                      return alert('Sorry, your message was unable to be sent at this time due to an error.\n' + resp.status + '\n\nPlease try again later or send your message to support@infernocomms.com.\n\nApologies for the inconvience.')
                    }

                    setFormSent(true)
                  })
                  .catch((err) => {
                    alert(err)
                    setSubmitting(false)
                  })
              }}
            >

              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (

              <Form className="uk-form-stacked">

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="contact-name">Name</label>
                  <div className="uk-form-controls">
                    <div className="uk-inline">
                      <span className="uk-form-icon" uk-icon="icon: user"></span>
                      <Field
                        className="uk-input uk-form-width-large"
                        name="name"
                        id="contact-name"
                        type="text"
                        disabled={isSubmitting || formSent}
                      />
                    </div>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="contact-email">Email</label>
                  <div className="uk-form-controls">
                    <div className="uk-inline">
                      <span className="uk-form-icon" uk-icon="icon: mail"></span>
                      <Field
                        className="uk-input uk-form-width-large"
                        name="email"
                        id="contact-email"
                        type="email"
                        disabled={isSubmitting || formSent}
                      />
                    </div>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="contact-email">Purpose</label>
                  <div className="uk-form-controls">
                      <Field
                        as="select"
                        className="uk-select uk-form-width-large"
                        name="purpose"
                        id="contact-purpose"
                        disabled={isSubmitting || formSent}
                        >
                          <option>Sales</option>
                          <option>Support</option>
                          <option>Networks</option>
                      </Field>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="contact-message">Message</label>
                  <div className="uk-form-controls">
                    <div className="uk-inline">
                      <Field
                        as="textarea"
                        className="uk-textarea uk-form-width-large"
                        name="message"
                        id="contact-message"
                        disabled={isSubmitting || formSent}
                      ></Field>
                    </div>
                  </div>
                </div>

                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <div className="uk-inline">
                      <button className="uk-button uk-button-primary" type="submit" disabled={isSubmitting || formSent}>Submit</button>
                    </div>
                  </div>
                </div>

                {Object.keys(errors).length !== 0 && (
                <div uk-alert className="uk-alert uk-alert-error">
                  <div className="icon">
                    <span uk-icon="warning"></span>
                  </div>
                  <div className="message">
                    <ul>
                      {Object.values(errors).map((err) => 
                      <li key={err}>{err}</li>
                      )}
                    </ul>
                  </div>
                </div>
                )}

                {formSent && (
                <div uk-alert className="uk-alert uk-alert-success">
                  <div className="icon">
                    <span uk-icon="check"></span>
                  </div>
                  <div className="message">
                    Thank you for your message, we will be in touch soon.
                  </div>
                </div>
                )}

              </Form>
              )}
            </Formik>
          </div>
        </div>

      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Contact Us"
    description="We're happy to answer any questions you may have, get in touch and we'll be back to you as soon as we can."
    path="/contact-us"
  />
)

export default ContactUsPage
